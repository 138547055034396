import style from './index.module.scss';
import MiniP from '../../../components/minip';

import pic2 from './img/2.jpg';
import pic3 from './img/3.jpg';
import pic5 from './img/5.jpg';
import pic6 from './img/6.jpg';

export default function Cell() {
  return (
    <div className={style.wrap}>
      <div className={style.first}>
        <video
          src='https://oss.life-decode.com/life_decode/block0108-01.mp4'
          autoPlay
          muted
          playsInline
          loop></video>
        <div>
          <div className={style.title}>优生优育应用</div>
          <div>
            <div className={style.subtitle}>数据保护和隐私保护</div>
            <div className={style.detail}>
              <div>
                提供去中心化的数据存储和管理方式，确保基因数据的安全性和隐私性，保护患者和准父母的敏感信息，避免
              </div>
              <div>
                数据泄露和滥用。同时，通过智能合约等技术手段，实现对基因数据的访问控制和使用授权。由于区块链的不
              </div>
              <div>
                可篡改性，确保数据的真实性和可信度，从而增强数据的安全性和隐私保护。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.sarea}>
        <div>
          <img src={pic2} alt='' />
          <div className={style.detail}>
            <div>数据存储与管理</div>
            <p>
              存储和管理大量的基因数据。传统的基因数据库通常由单一的实体控制，而区块链技术则实现数据的分布式存储，这不仅提高了数据的安全性，也降低了数据丢失的风险。
            </p>
          </div>
        </div>
      </div>
      <div className={style.sarea}>
        <div>
          <div className={style.detail}>
            <div>数据溯源与验证</div>
            <p>
              追踪和验证基因数据的来源和真实性。每一个基因数据通过区块链打上时间轴和唯一的数字签名，这使得任何人都可以验证这个数据是否被篡改过。
            </p>
          </div>
          <img src={pic3} alt='' />
        </div>
      </div>

      <div className={style.a4}>
        <MiniP
          title='激励机制设计'
          detail='通过智能合约设计激励机制，鼓励更多的人参与基因库的建设。设置一些规则，对于那些贡献高质量基因数据的用户给予一定的奖励，比如加密平台积分或其他形式的价值回报。'
          style={{ top: '68px' }}></MiniP>
      </div>
      <div className={style.marea}>
        <div className={style.intro}>
          <div className={style.l}>优化医疗流程和管理</div>
          <div className={style.r}>
            记录和存储医疗流程和管理信息：医生的诊断和治疗方案、患者的用药记录、医疗费用等信息。这些信息被记录在区块链上并可由授权的用户进行查询和访问，从而优化医疗流程和管理，提高医疗服务的效率和质量。
          </div>
        </div>
        <img src={pic5} alt='' />
      </div>

      <div className={style.miniarea}>
        <div className={style.detail}>
          <h3>基因数据的确权和交易</h3>
          <p>
            通过数字签名、时间戳等手段，区块链为基因数据提供明确的所有权证明。同时通过智能合约等技术手段，实现基因数据的买卖、交换等交易行为，促进基因数据的商业化利用。
          </p>
        </div>
        <div>
          {/* <img src={pic6} alt='' /> */}
          <video
            className={style.img}
            src='https://oss.life-decode.com/life_decode/block01008-02.mp4'
            autoPlay
            muted
            playsInline
            loop></video>
        </div>
      </div>

      <div className={style.protect}>
        <h3>知识产权保护</h3>
        <p>
          保护优生优育项目中的知识产权和创新成果。通过区块链记录新技术的申请信息、审批过程和授权使
          <br />
          用情况等，确保产权的合法性和安全性，同时也提高新技术的流转效率和推广应用。
        </p>
      </div>

      <div className={style.a8}>
        <div className={style.main}>
          <div className={style.content}>
            <h3>数据共享和协同研究</h3>
            <p>
              建立可信的数据共享平台，让医生、患者、医疗机构、政府机构等能够在遵守隐私保护和合规性的前提下，方便地获取、分享、共享和交换基因数据，开展联合研究，提高优生优育项目的整体水平。同时区块链的智能合约功能实现多方之间的自动协作和交易，提高多方之间的信任和合作效率。
            </p>
            <div className={style.listWrap}>
              <div className={style.list}>
                <div className={style.name}>协作者</div>
                <div className={style.item}>医生</div>
                <div className={style.item}>患者</div>
                <div className={style.item}>医疗机构</div>
                <div className={style.item}>政府机构</div>
              </div>
              <div className={style.list}>
                <div className={style.name}>基因数据</div>
                <div className={style.item}>获取</div>
                <div className={style.item}>分享</div>
                <div className={style.item}>共享</div>
                <div className={style.item}>交换</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
