import style from './index.module.scss';
import { Link } from 'react-router-dom';
const arr = [
  {
    name: '育种技术',
    list: [
      '基因编辑',
      '药食分子育种',
      '药食种质资源收集',
      '药食基因挖掘与鉴定',
    ],
  },
  {
    name: '培植技术',
    list: [
      '传感器',
      '无人机',
      '卫星遥感',
      '大数据处理',
      '机器学习算法',
      'AI制定智能化',
    ],
  },
];

export default function MedicalAndFood() {
  return (
    <div className={style.list}>
      <div className={style.content1}>
        <div className={style.txt}>
          <p className={style.title}>药食育种</p>
          <p className={style.desc}>致力于构建药食同源作物的育种与培植体系。</p>
        </div>
        <Link to='/medicalAndFood-detail'>
          <div className={style.btnBuy}>立即订购</div>
        </Link>
      </div>
      <div className={style.content2}>
        <video
          src='https://oss.life-decode.com/life_decode/qyjs0108.mp4'
          autoPlay
          muted
          playsInline
          loop></video>
        <div className={style.item}>
          <div className={style.sitem}>
            <div className={style.desc}>
              <p className={style.title}>前沿技术</p>
              <p className={style.detail}>
                深入探索药食同源作物的遗传特性、生长发育规律、营养成分。 <br />
                我们专注于选育和改良具有优良性状的药食同源作物新品种，提升其产量、品质和抗逆性。
              </p>
            </div>
            <div className={style.itemDesc}>
              {arr.map(({ name, list }, index) => {
                return (
                  <ul>
                    <li key={index}>{name}</li>
                    {list.map(val => {
                      return <li>{val}</li>;
                    })}
                  </ul>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={style.content3}>
        <div>
          <p className={style.title}>多方紧密合作</p>
          <p className={style.desc}>
            我们与高校、科研机构等紧密合作，共同推进相关研究和开发工作，不断优化和完善药食同源作物的育种与培植体系。
          </p>
        </div>
      </div>
    </div>
  );
}
