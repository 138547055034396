import style from './index.module.scss';

import pic1 from './img/1.png';
import pic2 from './img/2.png';
import pic3 from './img/3.png';
import pic4 from './img/4.png';

export default function Shop() {
  return (
    <div>
      <div className={style.top}>
        <span>在线商店</span>
      </div>
      <div className={style.shops}>
        <span>在线商店</span>
        <div className={style.list}>
          <div className={style.item}>
            <div className={style.imgwrap}>
              <img src={pic1} alt='' />
            </div>
            <div className={style.name}>
              小程序 <span>细胞保藏</span>
            </div>
          </div>
          <div className={style.item}>
            <div className={style.imgwrap}>
              <img src={pic4} alt='' />
            </div>
            <div className={style.name}>
              小程序 <span>基因分型</span>
            </div>
          </div>
          <div className={style.item}>
            <div className={style.imgwrap}>
              <img src={pic3} alt='' />
            </div>
            <div className={style.name}>
              小程序 <span>药食育种</span>
            </div>
          </div>
          <div className={style.item}>
            <div className={style.imgwrap}>
              <img src={pic2} alt='' />
            </div>
            <div className={style.name}>
              小程序 <span>优生优育</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
