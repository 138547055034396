import style from './index.module.scss';
import logo from './logo.svg';
export default function Jianjie() {
  return (
    <div>
      <div className={style.area1}>
        <div className={style.content}>
          <img src={logo} alt='云解码' className={style.logo} />
          <div className={style.detail}>
            一家以科技创新为驱动力，基因库优化系统工程为基础，致力于推动生命科学发展的高新技术企业。
          </div>
          <div className={style.detail}>
            聚焦基因科技领域，提供全面、专业、高效的基因技术解决方案。
          </div>

          <div className={style.products}>
            <div className={style.item}>
              <div>细胞保藏</div>
              <span>基础产业</span>
            </div>
            <div className={style.item}>
              <div>基因分型</div>
              <span>成长产业</span>
            </div>
            <div className={style.item}>
              <div>药食育种</div>
              <span>发展产业</span>
            </div>
            <div className={style.item}>
              <div>优生优育</div>
              <span>探索产业</span>
            </div>
          </div>
        </div>
      </div>
      <div className={style.area}>
        <div className={style.desc}>
          <div className={style.left}>
            <div className={style.en}>
              Company
              <br />
              Profile
            </div>
            <div className={style.tag}>
              <span></span>
              <span></span>
            </div>
            <div className={style.cn}>公司简介</div>
          </div>
          <div className={style.right}>
            <div className={style.el}>
              <div className={style.top}>
                <span>01</span>深厚的技术底蕴
              </div>
              <div className={style.bottom}>
                先进的基因库建设、管理和加密技术，以顶尖的基因分型技术和领先优生优育研究为引擎
              </div>
            </div>
            <div className={style.el}>
              <div className={style.top}>
                <span>02</span>全方位基因库优化服务
              </div>
              <div className={style.bottom}>
                为科研机构、医疗机构、生物制药公司等社会各领域的C端客户提供基因测序、基因分型以及优生优育咨询等。
              </div>
            </div>
            <div className={style.el}>
              <div className={style.top}>
                <span>03</span>CRISPR-Cas9基因编辑技术
              </div>
              <div className={style.bottom}>
                公司专注于CRISPR-Cas9基因编辑技术的转化和应用，引入一种安全、透明和高效的数据管理区块链技术，提高基因数据的安全性、完整性和隐私性保护，以及各方协作的效率并提供适当的激励机制。
              </div>
            </div>
            <div className={style.el}>
              <div className={style.top}>
                <span>04</span>区块链加密技术
              </div>
              <div className={style.bottom}>
                基因库数据被加密并分散存储在网络的各个节点上，从而大大降低了数据泄露的风险。区块链技术的透明性和可追溯性，任何对基因库数据的访问和修改都可以被记录下来，并且随时可以进行审计和追踪。
              </div>
            </div>
            <div className={style.el}>
              <div className={style.top}>
                <span>05</span>支持多种加密算法和权限控制机制
              </div>
              <div className={style.bottom}>
                可以根据不同的应用场景和需求来灵活配置和调整。通过建立高质量的基因数据库、信息管理和加密系统，为用户提供基因数据管理和深度分型服务，提升研究成果的精度和效率，成为基因数据分析领域的领先企业。
              </div>
            </div>
            <div className={style.el}>
              <div className={style.top}>
                <span>06</span>专业的团队
              </div>
              <div className={style.bottom}>
                我们专业的团队具备深厚的基因学知识底蕴，精准解读基因变异，为您揭开基因组的奥秘，帮助您实现基因优化和精准育儿。
              </div>
            </div>
            <div className={style.el}>
              <div className={style.top}>
                <span>07</span>选择我们
              </div>
              <div className={style.bottom}>
                选择我们，您将开启基因优化新时代，让家族基因库更加卓越、强大。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
