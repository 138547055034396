import style from './index.module.scss';
import { Link } from 'react-router-dom';
import AMapLoader from '@amap/amap-jsapi-loader';
import { useEffect } from 'react';

export default function Contact() {
  let map = null;

  useEffect(() => {
    AMapLoader.load({
      key: '295e4faa6bd4357c95e849bb2334f810', // 申请好的Web端开发者Key，首次调用 load 时必填
      version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    })
      .then(AMap => {
        map = new AMap.Map('container', {
          // 设置地图容器id
          resizeEnable: true,
          center: [119.701651, 29.80458],
          zoom: 18,
        });
        let marker = new AMap.Marker({
          position: [119.701651, 29.80458],
          icon: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_r.png',
          anchor: 'bottom-center',
          offset: [2, -5],
        });
        map.add(marker);
      })
      .catch(e => {
        console.log(e);
      });

    return () => {
      map?.destroy();
    };
  }, []);
  return (
    <div className={style.base}>
      <div className={style.title}>联系我们</div>
      <div>
        <div className={style.item}>
          <div className={style.subTitle}>联系方式</div>
          <div className={style.detail}>电话：15257168741</div>
          <div className={style.detail}>邮箱：Xiemq0701@126.com</div>
        </div>
        <div className={style.item}>
          <div className={style.subTitle}>工作机会</div>
          <div className={style.detail}>
            <span>
              访问我们的<Link to='/jobs'>工作机会</Link>
              页面，浏览当前开放的职位信息
            </span>
          </div>
        </div>
        <div className={style.item}>
          <div className={style.subTitle}>公司地址</div>
          <div className={style.detail}>
            <span>浙江省杭州市西湖区新凉亭马家坞28号490室</span>
            <span>查看地图</span>
          </div>
          <div className={style.img} id='container'>
            {/* <img src='' alt='' /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
