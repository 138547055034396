import classNames from 'classnames';
import MiniP from '../../../components/minip';

import style from './index.module.scss';

import pic3 from './img/3.jpg';
import pic7 from './img/7.jpg';
import pic10 from './img/10.jpg';

export default function Cell() {
  return (
    <div className={style.wrap}>
      <div className={style.first}>
        <video
          src='https://oss.life-decode.com/life_decode/jyfxapp.mp4'
          autoPlay
          muted
          playsInline
          loop></video>
        <div>
          <div className={style.title}>基因分型应用</div>
          <div>
            <div className={style.subtitle}>数据安全和隐私保护</div>
            <div className={style.detail}>
              <div>
                提供一种去中心化的、安全的数据存储方式。每个人都可以拥有自己的基因数据副本，并且可以控制
              </div>
              <div>
                谁可以访问这些数据。在基因分型中，个人的基因数据被加密并存储在区块链上，确保数据的安全和隐私。只有通过私钥
              </div>
              <div>
                才能访问和解读这些数据，从而防止数据被未经授权的人获取和使用。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.area}>
        <MiniP
          title='数据共享和协作'
          detail='促进基因测序数据的共享和协作。不同的研究机构、医院和实验室在区块链平台上安全地共享和交换基因测序数据，以进行更深入的研究和合作，有助于提高科研效率，加速科学发现。'
          style={{ top: '68px' }}
        />
      </div>

      <div className={style.twoCol}>
        <div className={style.l}>
          <div>
            <div className={style.title}>基因分型服务</div>
            <div className={style.detail}>
              建立区块链基因分型服务平台，提供低成本的工业化基因分型服务。根据分布式计算资源，将分型任务分配给全球的分布式计算资源，提高分型的效率和准确性。
            </div>
          </div>
        </div>
        <div className={style.r}>
          <img src={pic3} alt='' />
        </div>
      </div>

      <div className={style.reserved}></div>

      <div className={style.area1}>
        <MiniP
          title='溯源和验证'
          detail='记录基因测序的整个过程，采样、实验室处理、数据分析等。这些信息被保存在区块链上，不容易被篡改，确保了测序结果的可信度。在医学诊断和研究中，这种不可篡改性对于确保患者基因信息的准确性至关重要。根据哈希函数和其他加密算法，可以确保数据没有被篡改或损坏，验证基因数据的准确性和完整性。'
          style={{ top: '68px' }}
        />
      </div>
      <div className={classNames(style.area, style.a5)}>
        <MiniP
          title='智能合约和自动化'
          detail='智能合约自动执行一些预定义的规则和条件。在基因测序中，智能合约用于自动化数据质量控制、分析流程管理等任务，从而提高工作效率和准确性。'
          style={{ bottom: '68px' }}
        />
      </div>
      <div className={style.a6}>
        <div className={style.tit}>去中心化的基因测序市场</div>

        <div className={style.tit1}>
          <div className={style.title}>
            创建一个去中心化的基因测序市场，允许个人、研究机构和公司在区块链平台上直接进行基因测序服务的交易，降低交易成本，提高市场的透明度和效率。
          </div>
        </div>
      </div>
      <div className={style.sarea}>
        <div>
          <img src={pic7} alt='' />
          <div className={style.detail}>
            <div>基因数据挖掘与分析</div>
            <p>
              通过区块链技术对海量的基因数据进行挖掘和分析，发现基因与疾病之间的关系，为精准医疗和个性化治疗提供支持。同时也将基因数据与环境、生活习惯等其他数据相结合，开展更全面的健康研究和管理。
            </p>
          </div>
        </div>
      </div>

      <div className={style.a8}>
        <div className={style.fixgane}>
          <div className={style.left}>保障消费者权益</div>
          <div className={style.right}>
            将消费者的基因数据写入区块链，变成数字健康资产。消费者通过私钥控制数据的访问和共享，确保数据的安全性和可信度。同时如果消费者发现潜在的健康问题，通过区
            <br />
            块链技术获取相关的医疗建议和治疗方案，提前改变生活习惯来减少发生概率。
          </div>
        </div>
      </div>

      <div className={style.a9}>
        <div className={style.fixgane}>
          <div className={style.left}>优化基因编辑过程</div>
          <div className={style.right}>
            区块链的智能合约和审计机制有助于加强人类基因编辑的管理和监管。
          </div>
        </div>
      </div>

      <div className={style.sarea}>
        <div>
          <div className={style.detail}>
            <div>数据交易</div>
            <p>
              基因数据的买卖交易。通过使用区块链加密货币或其他数字资产作为支付手段，建立一个公平、透明的数据市场。这有助于激励更多的人参与基因检测，并推动相关产业的发展。
            </p>
          </div>
          <img src={pic10} alt='' />
        </div>
      </div>
      <div className={classNames(style.area, style.a11)}>
        <div className={style.ganetitle}>
          <div className={style.top}>知识产权保护</div>
          <div className={style.bottom}>
            <div className={style.title}>
              利用区块链保护知识产权，确保研究成果得到公正的评价和奖励。通过数字签名和时间轴，研究者证明自己是某一基因数据的原创者，并获得相应的经济利益和社会认可。
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
