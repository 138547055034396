import classNames from 'classnames';
import MiniP from '../../components/minip';

import style from './index.module.scss';


export default function Science() {
  return (
    <div className={style.wrap}>
      <div className={style.topp}>
        <img src={require('./img/k1.jpg')} alt="" />
        {/* <div className={style.top}>
      </div> */}
        <p>强大的科研实力和创新能力</p>
      </div>

      <div className={style.mid}>

        <img src={require('./img/WechatIMG517.jpg')} alt="" />

        <div className={style.d}>
          <div className={style.top}>
            <div className={style.tt}>
              <span className={style.uderline}></span>
              <span className={style.s}></span>
              <span className={style.tr}>
                About  Professor
              </span>
            </div>
            <div className={style.padd}  >
              吕科兴  |   专家顾问
            </div>
            <div className={style.padd1} >
              中山大学博士，临床博士后，聘任于中山大学附属第一医院，耳鼻咽喉科。
            </div>

            <span className={style.bt}></span>
            <div className={style.padd2} >
              “  研究领域
            </div>
            <div className={style.padd3}>
              头颈鳞癌分子机制及精准诊疗研究。
            </div>
          </div>
        </div>
      </div>


      <div className={style.iner}>

        <div className={style.content}>
          <div className={style.title}>
            科研成果
          </div>
          <div className={style.item}>
            <div className={style.left}>
              01
            </div>
            <div className={style.right}>
              曾先后至香港大学李嘉诚医学院及美国密歇根 Rogel Cancer Center 进行学术访问学习。
            </div>
          </div>
          <div className={style.item}>
            <div className={style.left}>
              02
            </div>
            <div className={style.right}>
              主持国自然青年科学基金 1项，正在参与的国家自然科学基金 （面上项目） 3 项。
            </div>
          </div>
          <div className={`${style.item} ${style.last1}`}>
            <div className={style.left}>
              03
            </div>
            <div className={style.right}>
              以第一作者在国际知名期刊发表 SCI论著近10篇。
            </div>
          </div>
          <div className={style.bottom}>
            <ul>
              <li>l Lyu K*, Li Y*, Xu Y*, Yue H, Wen Y, Liu T, Chen S, Liu Q, Yang W, Zhu X et al: Using RNA sequencing to identify a putative lncRNA-associated ceRNA network in laryngeal squamous cell carcinoma. RNA biology 2020:1-13. （IF=4.2）</li>
              <li>l Lyu KX, Zhao J, Wang B, Xiong GX, Yang WQ, Liu QH, Zhu XL, Sun W, Jiang AY, Wen WP et al: Smartphone Application WeChat for Clinical Follow-up of Discharged Patients with Head and Neck Tumors: A Randomized Controlled Trial. Chinese medical journal 2016, 129(23):2816-2823. （IF=6.1）</li>
              <li>l Lyu K*, Xu Y*, Yue H, Li Y, Zhao J, Chen L, Wu J, Zhu X, Chai L, Li C et al: Long Noncoding RNA GAS5 Acts As A Tumor Suppressor In Laryngeal Squamous Cell Carcinoma Via miR-21. Cancer management and research 2019, 11:8487-8498. （IF=3.3）</li>
              <li>l Li, Y*., Tang, B*., Lyu, K*., Yue, H., Wei, F., Xu, Y., Chen, S., Lin, Y., Cai, Z., Guo, X., et al. (2022). Low expression of lncRNA SBF2-AS1 regulates the miR-302b-3p/TGFBR2 axis, promoting metastasis in laryngeal cancer. Molecular carcinogenesis 61, 45-58. （IF=4.6）</li>
              <li>l Li Y*, Lyu K*, Wen Y, Xu Y, Wei F, Tang H, Chen S, Wang Z, Zhu X, Wen W et al: Third or fourth branchial pouch sinus lesions: a case series and management algorithm. Journal of otolaryngology - head & neck surgery = Le Journal d'oto-rhino-laryngologie et de chirurgie cervico-faciale 2019, 48(1):61. （IF=3.4）</li>
              <li>l Zhao J*, Lv K*, Li ZH, Wu J, Gao W, Wong TS, Luo J, Qin H, Wang B, Fu Q et al: Functional significance of the long non-coding RNA RP11-169D4.1 as a metastasis suppressor in laryngeal squamous cell carcinoma by regulating CDH1. Oncology reports 2017, 38(1):211-220. （IF=4.2）</li>
              <li>l Aikins, M. E., Qin, Y., Dobson, H. E., Najafabadi, A. H., Lyu, K., Xu, Y., Xin, Y., Schwendeman, A., Wicha, M. S., Chang, A. E., et al. (2022). Cancer stem cell antigen nanodisc cocktail elicits anti-tumor immune responses in melanoma. Journal of Controlled Release.（IF=10.8）</li>
              <li>l Qin, Y., Lu, F., Lyu, K., Chang, A. E., and Li, Q. (2022). Emerging concepts regarding pro- and anti-tumor properties of B cells in tumor immunity. Frontiers in immunology 13, 881427. （IF=7.3）</li>
              <li>
                l Chen L*, Cai Z*, Lyu K, et al. A novel immune-related long non-coding RNA signature improves the prognosis prediction in the context of head and neck squamous cell carcinoma. Bioengineered 2021;12(1):2311-25. （IF=4.9）
              </li>
              <li>Wei F*, Fang R*, Lyu K, et al. Exosomal PD-L1 derived from head and neck squamous cell carcinoma promotes immune evasion by activating the positive feedback loop of activated regulatory T cell-M2 macrophage. Oral oncology 2023;145:106532. （IF=4.8）</li>
              <li>Gao W*, Wong TS*, Lv KX, Zhang MJ, Tsang RK, Chan JY: Detection of Epstein-Barr virus (EBV)-encoded microRNAs in plasma of patients with nasopharyngeal carcinoma. Head & neck 2018. （IF=2.9）</li>
            </ul>
          </div>
        </div>

      </div>


      <div className={style.mid}>
        <div className={style.d}>
          <div className={style.top1}>
            <div className={style.tt}>
              <span className={style.uderline}></span>
              <span className={style.s}></span>
              <span className={style.tr}>
                About  Professor
              </span>
            </div>
            <div className={style.padd}  >
              毛岱山  |   专家顾问
            </div>
            <div className={style.padd1} >
              厦门大学硕士毕业，在联想，熵链科技，海峡链等公司有十余年技术研究、架构设计和开发经验。
            </div>
            <span className={style.bt1}></span>
            <div className={style.padd2} >
              “  研究领域
            </div>
            <div className={style.padd3}>
              区块链底层技术和DApp应用系统设计和开发。
            </div>
          </div>
        </div>

        <img src={require('./img/WechatIMG518.jpg')} alt="" />

      </div>

      <div className={style.bot}>
        <div className={style.content}>
          <div className={style.title}>
            科研成果
          </div>
          <div>
            1、曾经担任过海峡链技术研究院院长，申请了几十项发明专利，其中区块链领域有十几项。
          </div>
          <div className={style.bottom}>
            <div className={style.item}>
              <div className={style.left}>
                01
              </div>
              <div className={style.right}>
                毛岱山，肖苗苗，刘群华等. 一种联盟链跨链中保护数据隐私的方法、装置及系统：113591104. [P]. 2021-11-02
              </div>
            </div>
            <div className={style.item}>
              <div className={style.left}>
                02
              </div>
              <div className={style.right}>
                毛岱山，肖苗苗，刘群华等. 一种联盟链跨链中保护数据隐私的方法、装置及系统：113591104. [P]. 2021-11-02
              </div>
            </div>
            <div className={style.item}>
              <div className={style.left}>
                03
              </div>
              <div className={style.right}>
                毛岱山，肖苗苗，刘群华等. 一种联盟链跨链中保护数据隐私的方法、装置及系统：113591104. [P]. 2021-11-02
              </div>
            </div>
            <div className={style.item}>
              <div className={style.left}>
                04
              </div>
              <div className={style.right}>
                毛岱山，肖苗苗，刘群华等. 一种联盟链跨链中保护数据隐私的方法、装置及系统：113591104. [P]. 2021-11-02
              </div>
            </div>
            <div className={style.item}>
              <div className={style.left}>
                05
              </div>
              <div className={style.right}>
                毛岱山，肖苗苗，刘群华等. 一种联盟链跨链中保护数据隐私的方法、装置及系统：113591104. [P]. 2021-11-02
              </div>
            </div>
            <div className={style.item}>
              <div className={style.left}>
                06
              </div>
              <div className={style.right}>
                l毛岱山，王超博，刘岭峰等. 一种数据验真方法、系统、电子设备及存储介质：117077214. [P]. 2023-11-17
              </div>
            </div>
          </div>

          <div className={style.end}>
            <div className={style.item}>
              2、参编 了TBI 《数字藏品发行平台技术要求与评测方法》 、IEEE P3221《Standard for Technical Requirements of Digital Collection Services Based on Blockchain Technologies》和《数据要素安全流通》。
            </div>
            <div className={style.item}>
              3、主导数字福建区块链应用服务技术平台的设计和开发工作以及 BSN 福建省区块链主干网的设计和开发工作。
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
