import classNames from 'classnames';
import style from './index.module.scss';
import { Link } from 'react-router-dom';
import mp1 from './img/1.mp4';
import mp2 from './img/2.mp4';
import mp3 from './img/3.mp4';
import mp4 from './img/4.mp4';
import jpg5 from './img/5.jpg';
import mp6 from './img/6.mp4';

export default function Eugenics() {
  return (
    <div className={style.list}>
      <div className={style.v1}>
        <video
          src='https://oss.life-decode.com/life_decode/1.ba9676b402030fddaf14.mp4'
          autoPlay
          muted
          loop
          width='100%'
          height='100%'
          className={style.videobg}></video>

        <div className={style.content1}>
          <div className={style.txt}>
            <p className={style.title}>基因宝</p>
            <p className={style.desc}>
              遗传检测，通过遗传学检测，了解夫妇的基因状况，
              <br />
              评估其生育健康风险如唐氏综合症、地中海贫血等遗传病的筛查。
            </p>
          </div>
          {/* <Link to='/'> */}
          <div className={style.btnBuy}>立即订购</div>
          {/* </Link> */}
        </div>
      </div>
      <div className={style.content2}>
        <video
          src='https://oss.life-decode.com/life_decode/2.79aff1c696ce198b451a.mp4'
          autoPlay
          muted
          loop
          width='1000px'
          height='561px'
          className={style.video}></video>
        <div className={`${style.itemDesc} ${style.ml}`}>
          <p className={style.ptitle}>慧育宝</p>
          <p className={style.pdesc}>
            产前诊断，通过血清学筛查、无创DNA检测和超声检查等手段，对胎儿进行染色体和基因检测，以判断胎儿是否存在遗传疾病或染色体异常等问题
          </p>
          {/* <Link to='/'> */}
          <div className={`${style.btnBuy} ${style.btn}`}>立即订购</div>
          {/* </Link> */}
        </div>
      </div>
      <div className={`${style.content2} ${style.contentEtr}`}>
        <div className={`${style.itemDesc} ${style.mr}`}>
          <p className={style.ptitle}>胚植宝</p>
          <p className={style.pdesc}>
            胚胎植入前遗传学诊断，在体外受精过程中，对胚胎进行遗传学检测，以筛选健康胚胎并避免遗传疾病的发生。
          </p>
          {/* <Link to='/'> */}
          <div className={`${style.btnBuy} ${style.btn}`}>立即订购</div>
          {/* </Link> */}
        </div>
        <video
          src='https://oss.life-decode.com/life_decode/3.30cfa9ba88fe546f830c.mp4'
          autoPlay
          muted
          loop
          width='1076px'
          height='605px'
          className={style.video}></video>
      </div>
      <div className={style.v1}>
        <video
          src='https://oss.life-decode.com/life_decode/4.9cb1ec13d22199d43a93.mp4'
          autoPlay
          muted
          loop
          width='100%'
          height='100%'
          className={style.videobg}></video>

        <div className={classNames([style.content1, style.content3])}>
          <div className={style.itemDesc}>
            <p className={style.ptitle}>优孕宝</p>
            <p className={style.pdesc}>
              胚胎发育和着床过程的调控，通过控制性超排卵、促排卵、胚胎培养等手段，调控胚胎发育和着床过程，提高胚胎着床率和妊娠成功率。
            </p>
          </div>
          {/* <Link to='/'> */}
          <div className={style.btnBuy}>立即订购</div>
          {/* </Link> */}
        </div>
      </div>
      <div className={classNames([style.content2, style.content4])}>
        <img src={jpg5} alt='' />

        <div className={`${style.itemDesc} ${style.ml}`}>
          <p className={style.ptitle}>优源宝</p>
          <p className={style.pdesc}>
            男性生育力保存，通过精子冷冻、精子库等手段，保存男性生育力，以避免男性不育或生育能力下降等问题。
          </p>
          {/* <Link to='/'> */}
          <div className={`${style.btnBuy} ${style.btn}`}>立即订购</div>
          {/* </Link> */}
        </div>
      </div>
      <div className={style.v1}>
        {/* <video
          src='https://oss.life-decode.com/life_decode/ysb.mp4'
          autoPlay
          muted
          loop
          width='100%'
          height='100%'
          className={style.videobg}></video> */}
        <div className={style.content5}>
          <p className={style.ptitle}>优生宝</p>
          <p className={style.pdesc}>
            辅助生殖，通过人工受精、体外受精等技术手段，帮助不孕不育夫妇实现妊娠和生育。
          </p>
          {/* <Link to='/'> */}
          <div className={style.btnBuy}>立即订购</div>
          {/* </Link> */}
        </div>
      </div>
    </div>
  );
}
