import style from './index.module.scss';
import { Link } from 'react-router-dom';
import mp2 from './img/2.mp4';
import mp3 from './img/3.mp4';

export default function Eugenics() {
  return (
    <div className={style.list}>
      <div className={style.content1}>
        <div className={style.txt}>
          <p className={style.title}>优生优育</p>
          <p className={style.desc}>
            帮助个体了解自己的遗传信息，为其提供个性化的健康管理方案
          </p>
        </div>
        <Link to='/eugenics-detail'>
          <div className={style.btnBuy}>立即订购</div>
        </Link>
      </div>
      <div className={style.content2}>
        <div className={style.item}>
          <div className={style.itemImg}>
            <video
              src='https://oss.life-decode.com/life_decode/2.144a1eff8af5afcdd4c4.mp4'
              autoPlay
              muted
              loop
              width='800px'
              height='450px'></video>
          </div>
          <div className={style.itemDesc}>
            <p className={style.stitle}>提供个性化的健康管理方案</p>
            <p className={style.sdesc}>
              孕前检查、遗传病风险评估、生殖健康咨询等。
            </p>
          </div>
        </div>
        <div className={style.vBg}>
          <video
            src='https://oss.life-decode.com/life_decode/3.77f96376f96dd65479ee.mp4'
            autoPlay
            muted
            loop
            width='100%'
            height='100%'
            className={style.video}></video>
          <div className={style.item2}>
            <p className={style.stitle}>制定个性化的优生优育方案</p>
            <p className={style.sdesc}>
              结合基因检测结果，修改遗传密码或重新编写的基因编辑技术，自我指导的选择性基因匹配技术，为客户制定个性化的优生优育方案，减少遗传疾病风险。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
