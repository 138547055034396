import classNames from 'classnames';
import style from './index.module.scss';
import { useState } from 'react';
import { Outlet, Link, NavLink } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/scss';

export default function GenotypingSwiper() {
  return (
    <div className={style.list}>
      <div className={style.v1}>
        <video
          src='https://oss.life-decode.com/life_decode/jyfx.mp4'
          autoPlay
          muted
          loop
          width='100%'
          height='100%'
          className={style.video}></video>
        <div className={style.content1}>
          <div className={style.div}>
            <div className={style.txt}>
              <p className={style.title}>基因分型</p>
            </div>
            <div className={style.idiv}>
              <p className={style.pdesc}>
                我们运用基因测序与组装、表观遗传学研究、多组学分析、生物信息学分析、功能注释与表达分析、
                <br />
                基因组关联分析、生物样本库管理技术和遗传信息管理与加密等尖端技术，从基因组、转录组、蛋白质组等多个层面深入探索遗传物质，为客户揭示医学上的遗传奥秘。
              </p>
              <Link to='/genotyping-detail'>
                <div className={style.btnBuy}>立即订购</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Swiper
        modules={[Autoplay, Pagination]}
        // direction='vertical'
        style={{ width: '100%', height: '1181px' }}
        autoplay
        loop
        // pagination={{
        //   clickable: true,
        //   bulletClass: 'my-bullet',
        //   bulletActiveClass: 'my-bullet-active',
        // }}
      >
        <SwiperSlide>
          <div className={style.content7}>
            <video
              src='https://oss.life-decode.com/life_decode/2-1.bd648d49102fe94fa3ed.mp4'
              autoPlay
              muted
              loop
              width='1600px'
              height='780px'
              className={style.video2}></video>
            <div className={style.item}>
              <div className={style.ldiv}>
                <span>
                  <i></i>
                  <i></i>
                </span>
                <p className={style.ptitle}>为预防和治疗提供重要的参考</p>
                <p className={style.pdesc}>
                  通过全基因组测序，SMRT可以帮助人们更好地了解疾病的遗传机制。
                </p>
              </div>
              <Link to='/genotyping-detail'>
                <div className={style.btnBuy}>立即订购</div>
              </Link>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={style.content7}>
            <video
              src='https://oss.life-decode.com/life_decode/2-2.7be274443e08ffe3e3ee.mp4'
              autoPlay
              muted
              loop
              width='1600px'
              height='780px'
              className={style.video2}></video>
            <div className={style.item}>
              <div className={style.ldiv}>
                <span className={style.span2}>
                  <i></i>
                  <i></i>
                </span>
                <p className={style.ptitle}>安全高效的数据管理</p>
                <p className={style.pdesc}>
                  面对海量的遗传信息，我们运用高效的数据存储、压缩和加密技术，确保遗传信息的安全和有效管理。我们的目标是为客户提供最优质的医学遗传学服务，以帮助客户更好地理解和管理自己的健康。
                </p>
              </div>
              <Link to='/genotyping-detail'>
                <div className={style.btnBuy}>立即订购</div>
              </Link>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
