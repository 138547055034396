import style from './index.module.scss';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/scss';

import pic31 from './img/3-1.jpg';
import pic32 from './img/3-2.jpg';
export default function Cell() {
  return (
    <div className={style.content}>
      <div className={style.area}>
        <video
          src='https://oss.life-decode.com/life_decode/xbbz.mp4'
          autoPlay
          muted
          playsInline
          loop></video>
        <div className={style.desc}>
          <div className={style.title}>保藏细胞</div>
          <div className={style.explan}>
            我们提供专业的细胞制备和保藏服务，包括皮肤细胞、脂肪细胞、干细胞等。
            <br />
            这些细胞可广泛应用于美容和治疗目的，帮助您实现健康和美丽的目标。
          </div>
          <div className={style.order}>
            {/* <Link className={style.item} to='/Celldetials'> */}
            立即订购
            {/* </Link> */}
          </div>
        </div>
      </div>

      <div className={style.show}></div>
      <div className={style.buy}>
        <div className={style.content}>
          <div className={style.top}>
            <div className={style.l}>活力细胞</div>
            <div className={style.r}>
              我们开发了基于干细胞的注射产品，可改善皮肤质量、减少皱纹、提亮肤色、紧致肌肤等。根据您的皮肤问题，我们将为您提供个性化定制服务，帮助您获得最佳的美容效果。
            </div>
          </div>
          <div className={style.order}>立即订购</div>
        </div>
      </div>
      {/* <div className={style.area} style={{ background: 'lime' }}>
        <div className={`${style.desc} ${style.top}`}></div>
        <div className={style.flex}>
          <div className={style.left}>
            <div>活力细胞</div>
            <Link className={style.item} to='/Celldetials'>
              立即订购
            </Link>
          </div>
          <div className={style.right}>
            我们开发了基于干细胞的注射产品，可改善皮肤质量、减少皱纹、提亮肤色、紧致肌肤等。根据您的皮肤问题，我们将为您提供个性化定制服务，帮助您获得最佳的美容效果。
          </div>
        </div>
      </div> */}
      <div className={style.swWrap}>
        <div className={style.container}>
          <Swiper
            modules={[Pagination, Autoplay]}
            // direction='vertical'
            style={{ width: '100%', height: '1286px' }}
            autoplay
            loop
            // pagination={{
            //   clickable: true,
            //   bulletClass: 'my-bullet',
            //   bulletActiveClass: 'my-bullet-active',
            // }}
          >
            <SwiperSlide>
              <div className={style.content7}>
                <img src={pic31} alt='' />
                <div className={style.item}>
                  <div className={style.ldiv}>
                    <span className={style.dot}>
                      <i></i>
                      <i></i>
                    </span>
                    <p className={style.ptitle}>Vita细胞</p>
                    <p className={style.pdesc}>
                      产品开发基于细胞疗法，可治疗各种皮肤疾病和损伤，如烧伤、疤痕、座疮、色素沉着等。
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={style.content7}>
                <img src={pic32} alt='' />
                <div className={style.item}>
                  <div className={style.ldiv}>
                    <span className={style.span2}>
                      <i></i>
                      <i></i>
                    </span>
                    <p className={style.ptitle}>Vita细胞</p>
                    <p className={style.pdesc}>
                      我们利用不同类型的细胞，如表皮细胞、纤维母细胞、免疫细胞等，来促进组织修复和再生，帮助您恢复健康肌肤。
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      <div className={style.arealast}>
        <div className={style.desc}>
          <div className={style.title}>
            Cell Bank <br />
            租赁优化解决方案
          </div>
          <div className={style.more}>
            <div className={style.detail}>
              我们建立了功能强大的细胞库，可保存具有特定功能的细胞，以备将来使用。同时，我们还提供细胞库的租赁服务，为其他医疗机构或研究机构提供细胞样品。消费者在租赁期间内使用这些细胞进行各种美容或医疗应用，帮助您实现更高效、更便捷的科研和应用。
            </div>
            <div className={style.order}>立即订购</div>
            {/* <Link className={style.item} to='/Celldetials'>
              立即订购
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}
