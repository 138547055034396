import style from './index.module.scss';
import classNames from 'classnames';

import block1 from './images/Slice 4@2x(1).png';
import block2 from './images/Slice 4@2x(2).png';
import block3 from './images/Slice 4@2x(3).png';
import block4 from './images/Slice 4@2x.png';

import { ReactComponent as Logo } from './images/logo.svg';
import { useEffect, useRef, useState } from 'react';
import { useMatches, NavLink, Link, useNavigate } from 'react-router-dom';

const data = [
  [
    {
      title: '细胞保藏',
      path: '/Celldetials',
      items: ['保藏细胞', '活力细胞', 'Vita细胞', 'CellBank租赁优化解决方案'],
    },
    {
      title: '基因分型',
      path: 'genotyping-detail',
      items: [
        '全外显子解码器',
        '全基因组解码器',
        '目标区域解码器',
        '人类线粒基因组解码器',
        '三代测序解码器',
        '850K甲基因组解码器',
        'SNP基因图谱',
        'ASA全基因组基因地图',
      ],
    },
    {
      title: '药食育种',
      path: '/medicalAndFood-detail',
      items: [
        {
          title: '药食植物育种',
        },
        {
          title: '药食动物育种',
        },
        '药食精萃',
        '药食NutriBoost',
      ],
    },
    {
      title: '优生优育',
      path: '/eugenics-detail',
      items: [
        {
          title: '基因宝',
          path: 'eugenics',
        },
        '慧育宝',
        '胚植宝',
        '优孕宝',
        '优源宝',
        '优生宝',
      ],
    },
  ],
  [
    {
      img: block4,
      path: 'block-cell',
      items: [
        {
          title: '细胞保藏应用',
          path: 'block-cell',
        },
      ],
    },
    {
      img: block1,
      path: 'block-gene',
      items: [
        {
          title: '基因分型应用',
          path: 'block-gene',
        },
      ],
    },
    {
      img: block2,
      path: 'block-medicine',
      items: [
        {
          title: '药食育种应用',
          path: 'block-medicine',
        },
      ],
    },
    {
      img: block3,
      path: 'block-prenatal',
      items: [
        {
          title: '优生优育应用',
          path: 'block-prenatal',
        },
      ],
    },
  ],
  [
    {
      path: 'jianjie',
      items: [
        {
          title: '公司简介',
        },
      ],
    },
    {
      path: 'aboutus',
      items: [
        {
          title: '关于我们',
        },
      ],
    },
  ],
  [
    {
      title: '小程序/APP',
      path: 'shop',
      items: [
        {
          title: '细胞保藏应用',
          path: 'shop',
        },
        {
          title: '药食育种应用',
          path: 'shop',
        },
        {
          title: '基因分型应用',
          path: 'shop',
        },
        {
          title: '优生优育应用',
          path: 'shop',
        },
      ],
    },
  ],
];

export default function Header() {
  const [cur, setCur] = useState(-1);
  const pre = useRef();
  function hide() {
    const timer = setTimeout(cur => {
      setCur(-1);
    }, 50);
    pre.current = timer;
  }
  function show(num) {
    if (pre.current) {
      clearTimeout(pre.current);
    }
    setCur(num);
  }
  let matches = useMatches();
  let customStyle = matches
    .filter(match => Boolean(match.handle?.style))
    .map(i => i.handle.style);

  const navigate = useNavigate();

  function handleClick(i) {
    if (!i.path) return;
    navigate(i.path);

    hide();
  }

  return (
    <div
      className={classNames(style.header, {
        [style.active]: cur > -1,
      })}
      style={{ background: customStyle.length ? customStyle[0].headerBg : '' }}
      onMouseLeave={hide}>
      <div
        className={style.logo}
        style={{ color: customStyle.length ? customStyle[0].headerColor : '' }}>
        <NavLink to='/'>
          <Logo />
        </NavLink>
      </div>
      <div
        className={style.nav}
        style={{ color: customStyle.length ? customStyle[0].headerColor : '' }}>
        <div
          className={classNames(style.navItem, { [style.on]: cur === 0 })}
          sort='1'
          onMouseOver={() => {
            show(0);
          }}>
          产品
        </div>
        <div
          className={classNames(style.navItem, { [style.on]: cur === 1 })}
          onMouseOver={() => {
            show(1);
          }}>
          区块链
        </div>
        <Link to='/science' className={style.navItem}>
          科研
        </Link>
        <div
          className={classNames(style.navItem, { [style.on]: cur === 2 })}
          onMouseOver={() => {
            show(2);
          }}>
          发现
        </div>
        <div
          className={classNames(style.navItem, { [style.on]: cur === 3 })}
          onMouseOver={() => {
            show(3);
          }}>
          在线商店
        </div>
      </div>
      <div className={style.wrap}>
        <div className={style.lists}>
          {cur > -1 &&
            data[cur].map(item => {
              return (
                <div className={style.group}>
                  {item.title && (
                    <div
                      className={style.title}
                      onClick={() => handleClick(item)}>
                      {item.title}
                    </div>
                  )}
                  {item.img && (
                    <img
                      src={item.img}
                      className={style.img}
                      alt=''
                      onClick={() => handleClick(item)}
                    />
                  )}
                  <div
                    className={classNames(style.products, {
                      [style.center]: item.img,
                    })}
                    style={{
                      gridTemplateRows: `repeat(${cur === 3 ? 2 : 4}, auto)`,
                    }}>
                    {item.items.map(i => {
                      return (
                        <div className={style.i}>
                          <span onClick={() => handleClick(item)}>
                            {i.title || i}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
