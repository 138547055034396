import style from './index.module.scss';
import MiniP from '../../../components/minip';

import png1 from './img/1.png';
import png2 from './img/2.png';
import png3 from './img/3.png';
import png4 from './img/4.png';
export default function Cell() {
  return (
    <div className={style.wrap}>
      <div className={style.first}>
        <video
          src='https://oss.life-decode.com/life_decode/xb1.mp4'
          autoPlay
          muted
          playsInline
          loop></video>
        <div>
          <div className={style.title}>细胞保藏应用</div>
          <div>
            <div className={style.subtitle}>细胞来源和去向追溯</div>
            <div className={style.detail}>
              <div>
                将细胞的来源和去向信息记录在区块链上，轻松查看样本的完整历史记录，
              </div>
              <div>细胞的采集、运输、处理、存储和使用的全过程</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className={style.area}>
          <MiniP
            title='细胞信息管理'
            detail='建立区块链细胞信息管理平台，对细胞的种类、状态、质量、来源、处理方法等相关信息进行全面管理。'
            style={{ top: '68px' }}
          />
        </div>
        <div className={style.area}>
          <MiniP
            title='智能合约执行'
            detail='智能合约用于规定细胞的使用范围和条件，确保细胞的合法使用和避免滥用。智能合约自动执行合同条款，减少人为干预和错误，提高合同的执行效率和可信度。'
            style={{ bottom: '68px' }}
          />
        </div>
        <div className={style.area}>
          <video
            src='https://oss.life-decode.com/life_decode/xb2.mp4'
            autoPlay
            muted
            playsInline
            loop></video>
          <MiniP
            title='细胞质量安全管理'
            detail='根据区块链去中心化特点建立细胞质量安全管理的平台。通过记录和查询细胞的检测数据、质量标准等信息，实现对细胞质量安全的全面监控和管理，提高细胞的质量安全水平。'
            style={{ top: '68px' }}
          />
        </div>
      </div>
      <div className={style.introduce}>
        <div className={style.content}>
          <div className={style.item}>
            <img src={png1} alt='' />
            <div>存储位置</div>
          </div>
          <div className={style.item}>
            <img src={png2} alt='' />
            <div>使用时间</div>
          </div>
          <div className={style.item}>
            <img src={png3} alt='' />
            <div>使用范围</div>
          </div>
          <div className={style.item}>
            <img src={png4} alt='' />
            <div>使用目的</div>
          </div>
        </div>
      </div>
      <div className={style.last}>
        <MiniP
          title='细胞存储和使用管理'
          detail='建立细胞存储和使用的管理平台，对细胞的存储和使用在区块链平台上实现全面管理记录和查询细胞的存储和使用情况，提高细胞使用的透明度和可信度。'
          style={{ top: '68px' }}
        />
      </div>
    </div>
  );
}
