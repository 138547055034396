import style from './index.module.scss';

export default function MiniP({ title, detail, ...props }) {
  return (
    <div className={style.mini} {...props}>
      <div className={style.l}>{title}</div>
      <div className={style.r}>{detail}</div>
    </div>
  );
}
