import style from './index.module.scss';
export default function Privacy() {
  return (
    <div className={style.wrap}>
      <div className={style.top}>
        <div className={style.title}>隐私与法律</div>
      </div>

      <div className={style.detail}>
        <div className={style.title}>隐私</div>
        <div className={style.p}>
          我们在网站运营过程中会收集用户的一些信息，包括但不限于姓名、邮箱、电话号码等。我们承诺，在未经用户允许的情况下，不会向任何第三方出售或泄露用户的个人信息。
        </div>
        <div className={style.p}>
          我们采取了必要的技术和管理措施来保护用户信息的安全，包括使用加密技术、限制员工对用户信息的访问等。我们也会定期对信息安全措施进行审查和更新，以确保用户信息的安全性。
        </div>
        <div className={style.title}>法律</div>
        <div className={style.p}>
          本网站的所有权归属于杭州云解码基因技术有限公司（以下简称“我们”或“本公司”）。用户在使用本网站时，应该遵守本公司的规定和法律法规。
        </div>
        <div className={style.p}>
          用户在使用本网站时，必须遵守以下服务条款：不得发布违法、不良、诽谤、侵权或含有病毒等有害信息的的内容；不得利用本网站进行欺诈或不正当竞争；不得传播虚假信息或未经证实的消息；不得干扰本网站的运营秩序或其他用户的使用体验。
        </div>
        <div className={style.p}>
          如果您在使用本网站时遇到任何问题或对我们的服务有任何建议和意见，可以通过以下方式联系我们：通过网站上的联系方式提交投诉或建议。我们会及时处理并回复您的投诉或建议。
        </div>
      </div>
    </div>
  );
}
