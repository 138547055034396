import style from './index.module.scss';
import { Link } from 'react-router-dom';

export default function Cell() {
  return (
    <div className={style.content}>
      <div className={style.area}>
        <video
          src='https://oss.life-decode.com/life_decode/xbbz0108.mp4'
          autoPlay
          muted
          playsInline
          loop></video>
        <div className={style.desc}>
          <div className={style.title}>细胞保藏</div>
          <div className={style.explan}>
            细胞样本获取和保藏是细胞科学研究中至关重要的环节。
          </div>
          <Link to='/Celldetials' className={style.order}>
            立即订购
          </Link>
        </div>
      </div>

      <div className={style.show1}></div>
      <div className={style.buy}>
        <div className={style.content}>
          <div className={style.top}>
            <div className={style.l}>获取细胞</div>
            <div className={style.r}>
              我们采用流式细胞分选、微流控和芯片式细胞分析系统等技术，高效、快速、准确地获取细胞，提高细胞获取和分析的效率和准确性。
            </div>
          </div>
          <Link to='/Celldetials' className={style.order}>
            立即订购
          </Link>
        </div>
      </div>
      <div className={style.buy}>
        <div className={style.content}>
          <div className={style.top}>
            <div className={style.l}>保藏细胞</div>
            <div className={style.r}>
              我们采用液氮保存法、液氮加DMSO保存法、超低温冷冻保存法和低温保存法等先进的冷冻技术，确保细胞的质量和活性，为细胞科学研究提供高质量的数据源。
            </div>
          </div>
          <Link to='/Celldetials' className={style.order}>
            立即订购
          </Link>
        </div>
      </div>
      <div className={style.show2}></div>
      <div className={style.onePic}></div>

      <div className={style.last}>
        <div>
          <div className={style.title}>涵盖全面细胞服务</div>
          <div className={style.detail}>
            我们的细胞样本获取和保藏服务涵盖了皮肤细胞、脂肪细胞、干细胞等具有再生和修复功能的细胞。我们提供细胞、组织样本及生物信息数据的加密保
            <br />
            存，确保客户的数据安全和隐私保护。我们的服务旨在为细胞科学研究提供高质量的数据源，为客户的科研工作提供有力支持
          </div>
          <Link to='/Celldetials' className={style.order}>
            立即订购
          </Link>
        </div>
      </div>
    </div>
  );
}
