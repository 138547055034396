import style from './index.module.scss';
import MiniP from '../../../components/minip';
import classNames from 'classnames';
import { useState } from 'react';
import pic2 from './img/2.jpg';
import video4 from './img/4.mp4';
import pic5 from './img/5.jpg';

import sw1 from './img/3-1.jpg';
import sw2 from './img/3-2.jpg';
import sw3 from './img/3-3.jpg';
import sw4 from './img/3-4.jpg';

// https://oss.life-decode.com/life_decode

export default function Cell() {
  const data = [
    {
      title: '药食作物的培植过程和生长情况',

      img: sw1,
    },
    {
      title: '药食作物的培植过程和生长情况',

      img: sw2,
    },
    {
      title: '药食作物的收获和加工过程',

      img: sw3,
    },
    {
      title: '药食作物的质量检测和食品安全信息',

      img: sw4,
    },
  ];

  const [cur, setCur] = useState(0);

  return (
    <div className={style.wrap}>
      <div className={style.first}>
        <div>
          <div className={style.title}>药食育种应用</div>
          <div>
            <div className={style.subtitle}>育种数据记录和追踪</div>
            <div className={style.detail}>
              <div>
                记录育种过程中的各种数据，种子的来源、品种、生长环境、生长过程等。
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={style.sarea}>
        <div>
          <video
            src='https://oss.life-decode.com/life_decode/zzzl.mp4'
            autoPlay
            muted
            playsInline
            loop></video>
          <div className={style.detail}>
            <div>种子质量管理</div>
            <p>
              种子质量的全程监控和管理。在育种过程中对种子的质量进行实时检测，并将检测数据记录在区块链上。
            </p>
          </div>
        </div>
      </div>
      <div className={style.swiper}>
        <div className={style.wrap}>
          <MiniP
            title='培植记录管理'
            detail='数据上传至区块链平台，并通过智能合约进行自动处理和交互。智能合约根据事先约定的条件自动执行，确保参与各方的权益得到保障。培植者获得合理的报酬消费者获得优质的产品。'
            style={{ top: '48px' }}></MiniP>
          <div className={style.img}>
            <img src={data[cur].img} alt='' />
          </div>
          <div className={style.list}>
            {data.map((i, index) => (
              <div
                className={classNames(style.item, {
                  [style.on]: index === cur,
                })}
                key={index}
                onClick={() => {
                  setCur(index);
                }}>
                <div className={style.progress}></div>
                <div className={style.title}>{i.title}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={style.miniarea}>
        <div className={style.detail}>
          <h3>智能合约</h3>
          <p>
            药食育种培植的相关信息，种子来源、培植环境、施肥施药情况、收获时间等记录到区块链上并通过智能合约自动执行相关的业务流程。
          </p>
          <div>
            当某种药材达到预设的生长条件时，智能合约自动触发支付给培植者相应的报酬。
          </div>
          <div>
            当发现某种药材存在质量问题时，智能合约自动停止相关交易并启动退货退款流程。
          </div>
        </div>
        <div>
          {/* <img src={pic4} alt='' /> */}
          <video
            src='https://oss.life-decode.com/life_decode/4.66cb5c3b6c605370f899.mp4'
            autoPlay
            muted
            playsInline
            loop></video>
        </div>
      </div>

      <div className={style.sarea}>
        <div>
          <div className={style.detail}>
            <div>供应链管理</div>
            <p>
              区块链技术将药食产品的生产、加工、运输、销售等各个环节的信息进行整合和记录，实现信息的透明化和可追溯性，提高供应链管理的效率和可信度。
            </p>
          </div>
          <img src={pic5} alt='' />
        </div>
      </div>

      <div className={style.a6}>
        <MiniP
          title='溯源与防伪'
          detail='通过在区块链上记录每一个产品的唯一标识和生产、加工、运输、销售等各个环节的信息，实现药食产品的溯源和防伪。'
          style={{ top: '68px' }}></MiniP>
      </div>

      <div className={style.a7}>
        <div className={style.content}>
          <h3>数据共享与协作</h3>
          <p>
            通过区块链技术实现药食育种和培植体系中各个参与方的数据共享和协作。
          </p>
          <div className={style.listWrap}>
            <div className={style.list}>
              <div className={style.name}>协作者</div>
              <div className={style.item}>育种者</div>
              <div className={style.item}>消费者</div>
              <div className={style.item}>科研机构</div>
              <div className={style.item}>农户、加工企业</div>
            </div>
            <div className={style.list}>
              <div className={style.name}>记录和共享数据 </div>
              <div className={style.item}>品种信息</div>
              <div className={style.item}>培植环境</div>
              <div className={style.item}>生长过程</div>
              <div className={style.item}>品质检测</div>
            </div>
          </div>
        </div>
      </div>

      <div className={style.protect}>
        <h3>数据共享与协作</h3>
        <p>
          通过区块链技术可以记录新品种的申请信息、审批过程和授权使用情况等，
          <br />
          确保产权的合法性和安全性。
        </p>
      </div>
    </div>
  );
}
