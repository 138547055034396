import style from './index.module.scss';
import { useMatches, Link } from 'react-router-dom';

export default function Footer() {
  let matches = useMatches();
  let customStyle = matches
    .filter(match => Boolean(match.handle?.style))
    .map(i => i.handle.style);

  return (
    <div className={style.footer}>
      <div
        className={style.items}
        style={{
          color: customStyle.length ? customStyle[0].footerColor : '',
        }}>
        <Link className={style.item} to='/license'>
          营业执照
        </Link>
        <Link className={style.item} to='/privacy'>
          隐私与法律
        </Link>
        <Link className={style.item} to='/contact'>
          联系我们
        </Link>
        <div className={style.item}>帮助</div>
        <Link
          className={style.item}
          to='https://beian.miit.gov.cn/'
          target='_blank'>
          浙ICP备2023048689号
        </Link>
      </div>
    </div>
  );
}
