import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ErrorPage from './error-page';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Home from './pages/home';
import Contact from './pages/contact';
import Jobs from './pages/jobs';
import Cell from './pages/cell';
import Celldetials from './pages/Celldetials';
import Jianjie from './pages/jianjie';
import AboutUs from './pages/aboutus';
import License from './pages/license';
import Privacy from './pages/privacy';
import Shop from './pages/shop';
//科研
import Science from './pages/science';

// 区块链
import BlockCell from './pages/block/cell';
import BlockGene from './pages/block/gene';
import BlockMedicine from './pages/block/medicine';
import Blockprenatal from './pages/block/prenatal';
//优生优育
import Eugenics from './pages/eugenics/list';
import EugenicsDetail from './pages/eugenics/detail';
//药食育种
import MedicalAndFood from './pages/MedicalAndFood/list';
import MedicalAndFoodDetail from './pages/MedicalAndFood/detail';

//基因分型详情
import GenotypingDetail from './pages/genotyping/detail';

//基因分型2轮播图
import GenotypingSwiper from './pages/genotyping/list';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,

    children: [
      {
        index: true,
        element: <Home />,
        handle: {
          style: {
            footerColor: '#fff',
          },
        },
      },
      {
        path: 'contact',
        element: <Contact />,
        handle: {
          style: {
            headerBg: '',
            headerColor: '#000',
            paddingBottom: 139,
          },
        },
      },
      {
        path: 'science',
        element: <Science />,
        handle: {
          style: {
            paddingBottom: 0,
          },
        },
      },
      {
        path: 'cell',
        element: <Cell />,
        handle: {
          style: {
            headerBg: '',
            headerColor: '#000',
            paddingBottom: 120,
            // footerColor: '#000',
          },
        },
      },
      {
        path: 'jianjie',
        element: <Jianjie />,
        handle: {
          style: {
            headerBg: '',
            headerColor: '#000',
            // footerColor: '#000',
          },
        },
      },
      {
        path: 'Celldetials',
        element: <Celldetials />,
        handle: {
          style: {
            // footerColor: '#000',
          },
        },
      },
      {
        path: 'aboutus',
        element: <AboutUs />,
        handle: {
          style: {
            headerBg: '',
            // footerColor: '#000',
          },
        },
      },
      {
        path: 'jobs',
        element: <Jobs />,
        handle: {
          style: {
            headerBg: '',
            headerColor: '#000',
            paddingBottom: 120,
            // footerColor: '#000',
          },
        },
      },
      {
        path: 'privacy',
        element: <Privacy></Privacy>,
        handle: {
          style: {
            headerBg: '',
            headerColor: '#000',
            paddingBottom: 120,
            // footerColor: '#000',
          },
        },
      },
      {
        path: 'license',
        element: <License></License>,
        handle: {
          style: {
            headerBg: '',
            headerColor: '#000',
            paddingBottom: 145,
            // footerColor: '#000',
          },
        },
      },
      {
        path: 'block-cell',
        element: <BlockCell />,
        handle: {
          style: {
            headerBg: '',
            headerColor: '#000',
            paddingBottom: 120,
            // footerColor: '#000',
          },
        },
      },
      {
        path: 'block-gene',
        element: <BlockGene />,
        handle: {
          style: {
            paddingBottom: 120,
            // footerColor: '#000',
          },
        },
      },
      {
        path: 'block-medicine',
        element: <BlockMedicine />,
        handle: {
          style: {
            headerBg: '',
            paddingBottom: 120,
            // footerColor: '#000',
          },
        },
      },
      {
        path: 'block-prenatal',
        element: <Blockprenatal />,
        handle: {
          style: {
            headerBg: '',
            paddingBottom: 0,
            // footerColor: '#000',
          },
        },
      },
      {
        path: 'eugenics',
        element: <Eugenics />,
        handle: {
          style: {
            headerBg: '',
            headerColor: '#000',
            paddingBottom: 0,
            // footerColor: '#000',
          },
        },
      },
      {
        path: 'eugenics-detail',
        element: <EugenicsDetail />,
        handle: {
          style: {
            paddingBottom: 0,
            footerColor: '#000',
          },
        },
      },
      {
        path: 'medicalAndFood',
        element: <MedicalAndFood />,
        handle: {
          style: {
            paddingBottom: 0,
            // footerColor: '#000',
          },
        },
      },
      {
        path: 'medicalAndFood-detail',
        element: <MedicalAndFoodDetail />,
        handle: {
          style: {
            paddingBottom: 0,
            // footerColor: '#000',
          },
        },
      },
      {
        path: 'genotyping-detail',
        element: <GenotypingDetail />,
        handle: {
          style: {
            headerBg: '',
            headerColor: '#000',
            paddingBottom: 0,
            // footerColor: '#000',
          },
        },
      },
      {
        path: 'genotyping-swiper',
        element: <GenotypingSwiper />,
        handle: {
          style: {
            paddingBottom: 0,
            // footerColor: '#000',
          },
        },
      },
      {
        path: 'shop',
        element: <Shop />,
        handle: {
          style: {
            headerBg: '',
            headerColor: '#000',
            paddingBottom: 117,
            // footerColor: '#000',
          },
        },
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
