import classNames from 'classnames';
import style from './index.module.scss';
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/scss';
import 'swiper/css/pagination';
import png1 from './img/detail/2-1.jpg';
import png2 from './img/detail/2-2.jpg';
import png3 from './img/detail/2-3.jpg';
import png4 from './img/detail/2-4.jpg';
import jpg5 from './img/5.jpg';
import jpg6 from './img/6.jpg';
import jpg71 from './img/7-1.jpg';
import jpg72 from './img/7-2.jpg';

import jpg91 from './img/9-1.jpg';
import jpg92 from './img/9-2.jpg';
import jpg11 from './img/11.jpg';
import jpg111 from './img/11-1.jpg';
import jpg131 from './img/13-1.jpg';
import jpg132 from './img/13-2.jpg';
import jpg151 from './img/15-1.jpg';
import jpg152 from './img/15-2.jpg';
import jpg16 from './img/16.jpg';
const arr = [
  {
    name: '应用潜力巨大',
    content:
      '可以帮助人们更好地理解疾病的分子机制，加速药物的研发进程。通过目标区域解码器，人们可以更加准确地检测和识别疾病相关的基因变异，从而为个体提供更加精准的诊断和治疗方案。',
  },
  {
    name: '研究基因组多样性和进化',
    content:
      'TRS还可以用于研究基因组多样性和进化，为人类遗传学和生物进化领域提供重要的参考数据。总的来说，目标区域解码器是一种强大的基因组分析工具，为医学研究和临床实践提供了重要的技术支持。',
  },
];
const arr2 = [
  {
    name: '高效的基因组分析方法',
    content:
      '能够检测肿瘤细胞中大量的甲基化位点，从而提供全面的肿瘤分子特征信息。通过分析甲基化水平的变化，可以深入了解肿瘤细胞的生物学特性，并为早期诊断和个体化治疗提供重要参考。',
  },
  {
    name: '对于临床医学具有重要意义',
    content:
      '可以更加准确地诊断肿瘤，及时采取有效的治疗措施，从而提高患者的生存率和治愈率。此外，这项技术还可以应用于癌症筛查和预防领域，为公众提供更加全面的健康保障。',
  },
];
const SnpArr = [
  {
    name: '测序法',
    content:
      '通过获取核酸序列信息，被视为SNP检测的“金标准”。该方法首先将含有SNP位点的靶标序列通过PCR扩增形成DNA片段，然后利用Sanger测序获取目标区域的核酸序列，并对SNP位点进行比对，从而确定是否存在变异位点。',
  },
  {
    name: 'TaqMan探针法',
    content:
      '该方法分别设计两种对应的探针，只有当探针与模板完全匹配时，在扩增过程中探针可被水解产生较强的荧光信号。如果探针与靶序列之间存在错配，其荧光强度将会减弱，因此可通过荧光强度检测SNP位点。',
  },
];

export default function GenotypingDetail() {
  const [isActive, setIsActive] = useState(0);
  const [isGActive, setGActive] = useState(0);
  const [isSActive, setSActive] = useState(0);

  // const handleActive = ()=>{
  //   setIsActive(isActive)
  //   console.log(isActive)
  // }
  function handleActive(val) {
    setIsActive(val);
  }
  function handleGActive(val) {
    setGActive(val);
  }
  function handleSActive(val) {
    setSActive(val);
  }
  return (
    <div className={style.list}>
      <div className={style.content1}>
        <div className={style.txt}>
          <p className={style.title}>全外显子解码器</p>
          <p className={style.desc}>
            全外显子解码器是一种基因组分析方法，通过序列捕获或靶向技术将外显子序列富集后再进行高通量测序，以解码人类基因组的蛋白质编码序列。
          </p>
        </div>
        <div>
          <div className={style.rate}>
            <div>
              <p>30Mb</p>
              <p>检测外显子区域中的致病突变</p>
            </div>
            <div className={style.percent}>
              <p>1%</p>
              <p>全基因组中的占比</p>
            </div>
          </div>
          <div className={style.btnBuy}>立即订购</div>
          <p>
            通过全外显子测序，可以提前采取预防措施，保护个体健康，并为个性化医疗提供重要参考。
          </p>
        </div>
      </div>
      <div className={style.content2}>
        <p className={style.pptitle}>全外显子测序四大模块检测</p>
        <div className={style.imglist}>
          <div className={style.imgItem}>
            <img src={png1} alt='' />
            <div className={style.desc}>
              <p>疾病风险模块</p>
              <p>揭示个体患病的风险</p>
            </div>
          </div>
          <div className={style.imgItem}>
            <img src={png2} alt='' />
            <div className={style.desc}>
              <p>精准用药模块</p>
              <p>指导个体化药物治疗</p>
            </div>
          </div>
          <div className={style.imgItem}>
            <div className={style.desc}>
              <p>特质基因模块</p>
              <p>解码个体特质和天赋</p>
            </div>
            <img src={png3} alt='' />
          </div>
          <div className={style.imgItem}>
            <div className={style.desc}>
              <p>单基因病模块</p>
              <p>诊断单基因遗传病</p>
            </div>
            <img src={png4} alt='' />
          </div>
        </div>
      </div>
      {/* <div className={classNames([style.content22, style.contentImg])}>
        <video
          src='https://oss.life-decode.com/life_decode/6.cd383274077207077672.mp4'
          autoPlay
          muted
          playsInline
          loop></video>
        <div className={style.item}>
          <div className={style.btns}>
            <p className={style.ptitle}>全基因组解码器</p>
            <div className={style.btnBuy}>立即订购</div>
          </div>
          <div className={classNames([style.pdesc, style.tdesc])}>
            全基因组解码器是一种高级基因组分析工具，利用全基因组测序技术，解码人体4100多种单基因遗传病以及所有常见疾病风险。通过精准的基因测序，能够为个体提供个性化的药物使用指导，同时结合个体的体质与特质基因，帮助人们采取正确的生活方式和保健方式。
          </div>
        </div>
      </div> */}
      <div className={style.buy}>
        <div className={style.content}>
          <div className={style.top}>
            <div className={style.l}>全基因组解码器</div>
            <div className={style.r}>
              全基因组解码器是一种高级基因组分析工具，利用全基因组测序技术，解码人体4100多种单基因遗传病以及所有常见疾病风险。通过精准的基因测序，能够为个体提供个性化的药物使用指导，同时结合个体的体质与特质基因，帮助人们采取正确的生活方式和保健方式。
            </div>
          </div>
          <div className={style.order}>立即订购</div>
        </div>
      </div>
      <div className={style.show}>
        <video
          src='https://oss.life-decode.com/life_decode/jy2.mp4'
          className={style.vd}
          autoPlay
          muted
          playsInline
          loop></video>
      </div>
      <div className={style.content4}>
        <div className={style.item}>
          <div className={style.desc}>
            <p className={style.ptitle}>全基因组解码器</p>
            <p className={style.pdesc}>
              能够针对个体存在的健康隐患采取个性化的预防措施 <br />
              为人们的健康、生活、教育、精准治疗等方面提供重要参考。
            </p>
          </div>
          <div className={style.btnBuy}>立即订购</div>
        </div>
        <div className={style.img}>
          <ul>
            <li>
              <p>
                23<span>对</span>
              </p>
              <p>检测人体染色体</p>
            </li>
            <li>
              <p>
                2.5<span>万</span>
              </p>
              <p>基因编码</p>
            </li>
            <li>
              <p>
                30<span>亿</span>
              </p>
              <p>碱基对</p>
            </li>
            <li>
              <p>
                99<span>%</span>
              </p>
              <p>准确率</p>
            </li>
          </ul>
        </div>
      </div>

      <div className={classNames([style.content4, style.content44])}>
        {/* <img src={jpg5} alt='' /> */}
        <video
          src='https://oss.life-decode.com/life_decode/jy1.mp4'
          autoPlay
          muted
          playsInline
          loop></video>
        <div className={style.item}>
          <div className={style.desc}>
            <p className={style.ptitle}>应用范围广泛</p>
            <p className={style.pdesc}>
              可以为医学研究、临床诊断、个性化治疗等提供有力支持。通过全基因组测序，人们可以更好地了解自己的基因信息，从而更好地管理健康和生活。
            </p>
          </div>
          <div className={style.btnBuy}>立即订购</div>
        </div>
      </div>
      <div className={style.content5}>
        <img src={jpg6} alt='' />
        <div className={style.item}>
          <div className={style.btns}>
            <p className={style.ptitle}>目标区域解码器</p>
            <div className={style.btnBuy}>立即订购</div>
          </div>
          <div className={classNames([style.pdesc, style.tdesc])}>
            目标区域解码器（TRS）是一种高效的基因组分析技术，通过定制目标基因组区域的探针，与基因组DNA进行杂交，从而将目标区域DNA富集后进行高通量测序。通过对大量样本的目标区域进行研究，TRS有助于发现和验证疾病相关的候选位点或基因，为临床诊断和药物开发提供重要的参考。
          </div>
        </div>
      </div>
      <div className={style.content6}>
        <img src={isActive === 0 ? jpg71 : jpg72} alt='' />
        <div className={style.tag}>
          {arr.map((item, i) => {
            return (
              <div
                key={i}
                className={isActive === i ? style.active : ''}
                onClick={() => handleActive(i)}>
                <p>{item.name}</p>
                <p>{item.content}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className={style.show1}>
        <video
          src='https://oss.life-decode.com/life_decode/jy3.mp4'
          className={style.vd}
          autoPlay
          muted
          playsInline
          loop></video>
      </div>
      <div className={style.buy} style={{ height: '220px' }}>
        <div className={style.content}>
          <div className={style.top}>
            <div className={style.l}>人线粒体解码器</div>
            <div className={style.r}>
              人线粒体基因组解码器是一种采用液相探针杂交捕获技术，将线粒体DNA富集后进行高通量测序的基因组分析方法。通过研究mtDNA变异位点和异质性，人线粒体基因组解码器有助于揭示线粒体相关疾病的致病机理。
            </div>
          </div>
          <div className={style.order}>立即订购</div>
        </div>
      </div>

      <div className={classNames([style.content51])}>
        <div className={style.itemDesc}>
          <div className={style.itemTP}>
            <img src={jpg91} alt='' />
            <div className={style.desc}>
              <p>线粒体-细胞中重要的能量代谢器官</p>
              <p>
                线粒体是细胞中重要的能量代谢器官，其功能异常与许多疾病的发生密切相关。因此，对线粒体基因组的研究对于理解疾病的病因和病理机制具有重要意义。人线粒体基因组解码器可以帮助人们更好地
                了解线粒体基因组的变异和异质性，从而为线粒体相关疾病的诊断、预防和治疗提供重要参考。
              </p>
            </div>
          </div>
          <div className={style.itemTP}>
            <div className={style.desc}>
              <p>广泛应用价值的基因组分析工具</p>
              <p>
                人线粒体基因组解码器还可以用于研究人类的进化、遗传多样性和人类学等领域。通过对不同人群线粒体基因组的比较和分析，可以帮助人们更好地理解人类的起源、迁徙和分化等重要问题。
              </p>
            </div>
            <img src={jpg92} alt='' />
          </div>
        </div>
      </div>
      <div className={style.content22}>
        <div className={style.item}>
          <div className={style.btns}>
            <p className={style.ptitle}>三代测序解码器</p>
            <div className={style.btnBuy}>立即订购</div>
          </div>
          <div className={classNames([style.pdesc, style.tdesc])}>
            三代测序解码器（SMRT）是一种先进的基因组分析工具，利用Oxford
            nanopore technologies和pacific
            biosciences公司的测序平台，对人类不同个体或群体进行全基因组测序。在个体或群体水平上进行生物信息分析，SMRT能够全面挖掘基因组的变异信息，为筛选疾病的致病及易感基因提供重要信息。
          </div>
        </div>
      </div>
      <div className={style.content7}>
        <Swiper
          className={style.spagination}
          modules={[Autoplay, Pagination]}
          // direction='vertical'
          style={{ width: '100%', height: '780px' }}
          // autoplay
          loop
          pagination={{
            el: '#pagination',
            clickable: true,
            bulletClass: `${style.myBullet}`,
            bulletActiveClass: `${style.myBulletActive}`,
          }}>
          <SwiperSlide>
            <img src={jpg11} alt='' />
          </SwiperSlide>
          <SwiperSlide>
            <img src={jpg111} alt='' />
          </SwiperSlide>
        </Swiper>

        <div className={style.item}>
          <div id='pagination' className={style.itemPagination}></div>
          {/* <span ><i></i><i></i></span> */}
          <p className={style.ptitle}>为预防和治疗提供重要的参考</p>
          <p className={style.pdesc}>
            通过全基因组测序，SMRT可以帮助人们更好地了解疾病的遗传机制。
          </p>
        </div>
      </div>
      <div className={style.content55}>
        <p className={style.ptitle}>850K甲基化基因图谱</p>
        <p className={style.pdesc}>
          850K甲基化基因图谱是一项利用甲基化芯片测序技术检测肿瘤细胞中甲基化水平的研究。
        </p>

        <div className={style.btnBuy}>立即订购</div>
      </div>
      <div className={classNames([style.content6, style.content66])}>
        <div className={style.tagDesc}>
          <p className={style.ptitle}>具有重要应用价值的医学研究</p>
          <p className={style.pdesc}>
            可以为肿瘤诊断和治疗提供重要的参考和支持。
          </p>
        </div>
        <img src={isGActive === 0 ? jpg131 : jpg132} alt='' />
        <div className={style.tag}>
          {arr2.map((item, i) => {
            return (
              <div
                key={i}
                className={isGActive === i ? style.active : ''}
                onClick={() => handleGActive(i)}>
                {/* <span></span> */}

                <p>{item.name}</p>
                <p>{item.content}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className={style.show1} style={{ height: '906px' }}>
        <video
          src='https://oss.life-decode.com/life_decode/jy4.mp4'
          className={style.vd}
          autoPlay
          muted
          playsInline
          loop></video>
      </div>
      <div className={style.buy} style={{ height: '240px' }}>
        <div className={style.content}>
          <div className={style.top}>
            <div className={style.l}>SNP基因图谱</div>
            <div className={style.r}>
              SNP基因图谱对于基因组学研究和医学应用具有重要意义。通过SNP检测，可以识别个体的基因型和变异位点，为基因疾病的诊断、预防和治疗提供重要参考。此外，SNP基因图谱还可以应用于进化研究、人类学和生物多样性等领域，为人类基因组研究提供全面的数据支持。
            </div>
          </div>
          <div className={style.order}>立即订购</div>
        </div>
      </div>
      <div className={classNames([style.content6, style.content10])}>
        <div className={style.tagDesc}>
          <p className={style.ptitle}>
            SNP基因图谱的检测方法包括测序法和TaqMan探针法等
          </p>
        </div>
        <img src={isSActive === 0 ? jpg151 : jpg152} alt='' />
        <div className={style.tag}>
          {SnpArr.map((item, i) => {
            return (
              <div
                key={i}
                className={isSActive === i ? style.active : ''}
                onClick={() => handleSActive(i)}>
                <p>{item.name}</p>
                <p>{item.content}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className={style.content11}>
        <div className={style.item}>
          <p className={style.ptitle}>ASA全基因组基因地图</p>
          <div className={style.itemDesc}>
            <p className={style.pdesc}>
              ASA全基因组芯片是基于超过9000名东亚人的全基因组数据设计的。这个芯片包含了70万个标记，是迄今为止对东亚地区的低频突变体（MAF为1-5%）解析能力最高的芯片。此外，ASA芯片针对东亚人群基因多态性的分析能力也得到了提升，根据千人基因组数据设计的芯片增加了2倍的东亚人群特异性位点。该芯片主要应用于优化设计果壳生物针对中国人群的ASA全基因组SNP芯片，它基于新测定的超过9000人东亚人群全基因组数据，以提供更准确、更精细的基因组学研究工具。
            </p>
            <div className={style.btnBuy}>立即订购</div>
          </div>
        </div>
        <img src={jpg16} alt='' />
      </div>
    </div>
  );
}
