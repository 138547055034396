import classNames from 'classnames';
import style from './index.module.scss';
import { Link } from 'react-router-dom';

export default function MedicalAndFoodDetail() {
  return (
    <div className={style.list}>
      <div className={style.content1}>
        <video
          src='https://oss.life-decode.com/life_decode/yszwyz.mp4'
          autoPlay
          muted
          loop
          width='1600px'
          height='780px'
          className={style.video2}></video>
        <div className={style.txt}>
          <p className={style.title}>药食植物育种</p>
          <p className={style.desc}>
            对药食植物进行品种选育和改良，提高其抗逆性、产量和品质等方面的性能，以获得具有优良性状和药食价值的品种。
          </p>
        </div>
        <div>
          <ul>
            <li>药食育种</li>
            <li>分子育种</li>
            <li>基因编辑</li>
            <li>分子标记辅助选择</li>
          </ul>
          {/* <Link to="/"> */}
          <div className={style.btnBuy}>立即订购</div>
          {/* </Link> */}
        </div>
      </div>
      <div className={style.content2}>
        <div className={style.item}>
          <div className={style.btns}>
            <p className={style.ptitle}>药食动物育种</p>
            {/* <Link to='/'> */}
            <div className={style.btnBuy}>立即订购</div>
            {/* </Link> */}
          </div>
          <div className={classNames([style.pdesc, style.tdesc])}>
            选择优良的种质资源、采用先进的育种、分子育种、基因编辑等技术手段来培育具有优良性状和药食价值的动物品种。对药食动物进行品种选育和改良，以获得具有优良性状和药食价值的品种。
          </div>
        </div>
      </div>
      <div className={classNames([style.content1, style.content3])}>
        <div className={style.item}>
          <div className={style.itemDesc}>
            <p className={style.ptitle}>药食精萃</p>
            <p className={style.pdesc}>
              通过提取和纯化技术，从药食植物和动物中提取出具有药食作用的活性物质，多糖、皂苷、黄酮、蛋白质等，并进行分离、纯化和鉴定。
            </p>
          </div>
          {/* <Link to='/'> */}
          <div className={style.btnBuy}>立即订购</div>
          {/* </Link> */}
        </div>
      </div>
      <div className={style.content5}>
        <video
          src='https://oss.life-decode.com/life_decode/ys2.mp4'
          autoPlay
          muted
          loop></video>
        <div>
          <p className={style.ptitle}>药食NutriBoost</p>
          <p className={style.pdesc}>
            通过综合利用药食植物和动物的种质资源，开发出具有营养保健、医疗康复等功能的药食产品，以满足人们的需求。
          </p>
          {/* <Link to='/'> */}
          <div className={style.btnBuy}>立即订购</div>
          {/* </Link> */}
        </div>
      </div>
    </div>
  );
}
