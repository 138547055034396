import style from './index.module.scss';
import { Link } from 'react-router-dom';

export default function Home() {
  return (
    <div className={style.content}>
      <div className={style.area}>
        <video
          src='https://oss.life-decode.com/life_decode/home1.mp4'
          autoPlay
          muted
          playsInline
          loop></video>
        <div className={style.desc}>
          <div className={style.title}>基因分型</div>
          <div className={style.more}>
            <Link className={style.item} to='/genotyping-swiper'>
              查看更多
            </Link>
          </div>
        </div>
      </div>
      <div className={style.area}>
        <div className={style.desc}>
          <div className={style.title}>细胞保藏</div>
          <div className={style.more}>
            <Link className={style.item} to='cell'>
              查看更多
            </Link>
          </div>
        </div>
      </div>
      <div className={style.area}>
        <div className={style.desc}>
          <div className={style.title}>药食育种</div>
          <div className={style.more}>
            <Link className={style.item} to='/medicalAndFood'>
              查看更多
            </Link>
          </div>
        </div>
      </div>

      <div className={style.area}>
        <video
          src='https://oss.life-decode.com/life_decode/6.cd383274077207077672.mp4'
          autoPlay
          muted
          playsInline
          loop></video>

        <div className={style.desc}>
          <div className={style.title}>优生优育</div>
          <div className={style.more}>
            <Link className={style.item} to='/eugenics'>
              查看更多
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
