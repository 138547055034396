import style from './index.module.scss';
import classNames from 'classnames';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import pic1 from './img/pic3-1.jpg';
import pic2 from './img/pic3-2.jpg';
import pic3 from './img/pic3-3.jpg';
import pic4 from './img/pic3-4.jpg';
export default function AboutUs() {
  const data = [
    {
      title: '科技进步，超越边界',
      intro:
        '我们致力于打造一个去中心化的基因库平台，让用户自由地存储、共享和交易基因数据。我们的平台将基于智能合约，保证所有交易的公平、公正和不可篡改。我们承诺将最先进的科技力量——区块链，引入基因库优化系统工程，以实现前所未有的数据安全与隐私保护。',
      img: pic1,
    },
    {
      title: '创新引领，改变命运',
      intro:
        '我们致力于区块链技术引领基因库优化系统工程的革新，以最高效、最安全的方式优化基因库工程。实现完全透明的数据管理，确保数据的安全性和隐私性，并为用户提供便捷的服务。创造一个安全、透明且高效的平台，让每个人都能参与到基因数据的保护和利用中来。',
      img: pic2,
    },
    {
      title: '共享智慧，造福全人类',
      intro:
        '我们确保每一个基因数据的所有权和使用权清晰明确，让每一位参与者都能从中受益。我们坚信，区块链不仅能够改变基因科技，为人类健康事业带来革命性的突破，更能为全人类的健康福祉和生命质量的提升贡献力量。',
      img: pic3,
    },
    {
      title: '携手前行，探索无限可能',
      intro:
        '我们将与全球范围内的科研机构和企业合作，共同推动基因科技的发展。我们的团队由一群富有经验的技术专家和行业领袖组成，他们有着丰富的经验和深厚的专业知识，将全力以赴地推进这个项目。我们邀请您加入我们的行列，一起探索基因科技的奇迹，开启新时代的基因革命。',
      img: pic4,
    },
  ];

  const [cur, setCur] = useState(0);
  return (
    <div className='wrap'>
      <div className={style.block}>
        <div>
          <div className={style.title}>关于我们</div>
          <div
            className={style.detail}
            style={{ marginTop: '80px', fontSize: '16px' }}>
            优化家族基因库，开启未来繁衍新时代
          </div>
        </div>
      </div>
      <div className={style.block}>
        <div>
          <div className={style.title}>点燃未来，开启基因纪元</div>
          <div className={style.detail} style={{ marginTop: '130px' }}>
            我们的使命是构建一个零距离、无障碍、超高效的基因信息互动平台，打破信息壁垒，让每个人都能自主掌握自己的基因信息（图），在基因科学的前沿开创一个优化未来的基因纪元。
          </div>
        </div>
      </div>
      <div className={style.swiper}>
        <div className={style.area}>
          <div className={style.img}>
            <img src={data[cur].img} alt='' />
          </div>
          <div className={style.list}>
            {data.map((i, index) => (
              <div
                className={classNames(style.item, {
                  [style.on]: index === cur,
                })}
                key={index}
                onClick={() => {
                  setCur(index);
                }}>
                <div className={style.progress}></div>
                <div className={style.title}>{i.title}</div>
                <div className={style.intro}>{i.intro}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={style.last}>
        <div>
          <div className={style.title}>加入我们</div>
          <Link to='/jobs'>
            <div className={style.job}>工作岗位</div>
          </Link>
        </div>
      </div>
    </div>
  );
}
