import { useEffect, useState } from 'react';
import style from './index.module.scss';

export default function Jobs() {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch('https://www.life-decode.com/api/config/personnel')
      .then(res => res.json())
      .then(res => {
        console.log(res);
        setData(res.data);
      });
  }, []);

  return (
    <div>
      <div className={style.img}>
        <img src='' alt='' />
        <div>工作岗位</div>
      </div>
      <div className={style.list}>
        <div className={style.line}>
          <div className={style.item}>标题</div>
          <div className={style.item}>类别</div>
          <div className={style.item}>地点</div>
        </div>
        {data.map((i, key) => {
          return (
            <div className={style.line} key={key}>
              <div className={style.item}>{i[0]}</div>
              <div className={style.item}>{i[1]}</div>
              <div className={style.item}>{i[2]}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
